export class CustomerChargeFilter {
  constructor(
    accountNo = 0,
    companyId = 0,
    storeId = 0,
    warehouseId = 0,
    chargeType = 'IN_PREP',
    beginDate = null,
    endDate = null,
    q = '',
  ) {
    this.accountNo = accountNo
    this.companyId = companyId
    this.storeId = storeId
    this.warehouseId = warehouseId
    this.chargeType = chargeType
    this.beginDate = beginDate
    this.endDate = endDate
    this.q = q
  }
}
export default CustomerChargeFilter
