import axios from '@axios'
import endpoints from '@/http/endpoints/index'

export default {
  // get customer balance by id
  async fetchById(id) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.customerCharges.Controller.findById(id))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  // get customer charges by company id
  async fetchAllByCompanyId(id) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.customerCharges.Controller.findAllByCompanyId(id))
        .then(response => {
          resolve({
            data: response.data.body.content,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  // get customer charges by store id
  async fetchAllByStoreId(id) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.customerCharges.Controller.findAllByStoreId(id))
        .then(response => {
          resolve({
            data: response.data.body.content,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  // get custoer charge by invoice id
  async fetchAllByInvoiceId(invoiceId) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.customerCharges.Controller.findAllByInvoiceId(invoiceId))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  // get cost group by id
  async fetchAllByProblemId(problemId) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.customerCharges.Controller.findAllByInvoiceId(problemId))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  // get all customer charges by query
  async fetchAllByQuery(filters, pageable) {
    const promise = new Promise((resolve, reject) => {
      axios
        .post(endpoints.customerCharges.Controller.findAllByQuery(pageable), filters)
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  // create customer charge
  async create(data) {
    const promise = new Promise((resolve, reject) => {
      axios
        .post(endpoints.customerCharges.Controller.create(), data)
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  // update customer charge
  async update(data) {
    const promise = new Promise((resolve, reject) => {
      axios
        .put(endpoints.customerCharges.Controller.update(), data)
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  // delete customer charge
  async deleteById(id) {
    const promise = new Promise((resolve, reject) => {
      axios
        .delete(endpoints.customerCharges.Controller.delete(id))
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },
}
