<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 px-0">
        <div class="card card-psh">
          <dx-data-grid
              v-if="true"
              id="customerChargeGrid"
              ref="customerChargeGridRef"
              :data-source="customerChargeDataSource"
              :height="setHeight"
              :allow-column-reordering="true"
              :allow-column-resizing="true"
              :column-auto-width="true"
              :show-column-lines="true"
              :show-row-lines="true"
              :show-borders="true"
              :hover-state-enabled="true"
              :row-alternation-enabled="true"
              :repaint-changes-only="true"
              @initialized="onInitialized"
              @content-ready="onContentReady"
              @toolbar-preparing="onToolbarPreparing($event)"
              @editor-preparing="onEditorPreparing"
            >
              <dx-scrolling column-rendering-mode="standard" />
              <dx-search-panel
                :highlight-search-text="true"
                :search-visible-columns-only="true"
                :visible="true"
                :width="300"
                placeholder="Search..."
              />
              <dx-sorting mode="single" />
              <dx-remote-operations :paging="true" :sorting="false" :filtering="false" />
              <dx-column data-field="accountNo" caption="Acc #" :width="80" :visible="hasPermission" alignment="center" cell-template="accountCell" />
              <dx-column data-field="companyName" caption="Company" cell-template="accountCell" :visible="hasPermission" />
              <template #accountCell="{ data }">
                <a href="#" @click="onFilterByAccountNoLink($event, data)">
                  <span>{{ data.value }}</span>
                </a>
              </template>
              <dx-column
                data-field="storeName"
                caption="Store Name"
                alignment="left"
              />
              <dx-column
                data-field="chargedAt"
                caption="Date"
                data-type="date"
                width="160"
              />
              <dx-column
                data-field="costGroup"
                caption="Cost Group"
              />
              <dx-column
                data-field="invoiceNo"
                caption="Invoice No"
                width="140"
                alignment="center"
                cell-template="invoiceCell"
              />
              <template #invoiceCell="{data}">
                <div>
                <div v-if="!data.data.invoiceNo || data.value===0" class="badge badge-pill badge-light-warning  d-block">
                  Pending
                </div>
                <router-link v-else :to="{ name: 'apps-invoice-preview', params: { id: data.data.invoiceId } }">
                  <div class="badge badge-pill badge-light-success  d-block">
                      {{ data.value }}
                  </div>
                </router-link>
                </div>
              </template>
              <dx-column
                data-field="chargeType"
                caption="Charge Type"
                width="140"
                alignment="center"
                cell-template="chargeTypeCell"
              />
              <template #chargeTypeCell="{data}">
                <div :class="resolveChargeTypeStyle(data.value)" class="badge badge-pill d-block text-capitalize">
                  {{ data.value }}
                </div>
              </template>
              <dx-column
                data-field="amount"
                caption="Amount"
                :format="{ style: 'currency', precision: 2, currency: 'USD' }"
                alignment="left"
                width="100"
              />
              <dx-column
                data-field="warehouseName"
                caption="Warehouse Name"
              />
              <dx-column
                data-field="notes"
                caption="Notes"
              />
              <dx-column v-if="hasCustomerChargePermission" data-field="id" caption="Actions" alignment="center" cell-template="itemActions" :width="100" :fixed="true" fixed-position="left" />
              <template #itemActions="{data}">
                <div>
                  <dx-util-button
                    type="warning" icon="bi bi-pencil-square"
                    class="mr-half" hint="Edit Charge Item"
                    @click="editItem(data)"
                  />
                  <dx-util-button
                    type="danger" icon="bi bi-trash-fill"
                    hint="Delete Charge Item"
                    @click="deleteItem(data)"
                  />
                </div>
              </template>
               <!--Begin    ::DataGrid toolbar -->
              <template #toolbarFilters>
                <div class="d-flex flex-row align-items-center">
                  <div v-if="hasAccountReadPermission" class="mr-1">
                    <dx-util-text-box
                      v-model="accountNo"
                      :show-clear-button="true"
                      mode="text"
                      placeholder="Account or Suite No"
                      @key-down="onFilterByAccountNo"
                      @value-changed="onFilterByAccountNo"
                    />
                  </div>
                  <div class="mr-1 w-35">
                    <dx-util-select-box
                      v-model="selectedWarehouse"
                      :data-source="warehouses"
                      display-expr="text"
                      value-expr="value"
                      @value-changed="searchByFilter"
                    />
                  </div>
                  <div class="mr-1">
                    <dx-util-select-box
                      v-model="selectedStore"
                      :data-source="stores"
                      display-expr="text"
                      value-expr="value"
                      @value-changed="searchByFilter"
                    />
                  </div>
                  <div class="mr-1">
                    <dx-util-select-box
                      v-model="selectedChargeType"
                      :data-source="chargeTypes"
                      display-expr="text"
                      value-expr="value"
                      @value-changed="searchByFilter"
                    />
                  </div>
                </div>
              </template>
              <dx-paging :page-size="pagerOptions.pageSize" />
              <dx-pager
                :allowed-page-sizes="pagerOptions.allowedPageSizes"
                :show-info="pagerOptions.showInfo"
                :show-navigation-buttons="pagerOptions.showNavigationButtons"
                :show-page-size-selector="pagerOptions.showPageSizeSelector"
                :visible="pagerOptions.visible"
              />
              <!--End    ::DataGrid toolbar -->
            </dx-data-grid>
          </div>
          <div class="col-12 px-0">
            <customer-charge-form
              :show-popup="isVisible"
              :warehouses="warehouses"
              :current-row="selectedRow"
              @form-saved="reloadAfterUpdate"
              @form-closed="isVisible=false"
            />
          </div>
      </div>

    </div>
  </div>
</template>

<script>
import GridBase from '@core/dev-extreme/mixins/grid/base'
import Filters from '@robustshell/mixins/filters'
import { confirm } from 'devextreme/ui/dialog'
import customerChargeService from '@/http/requests/finance/customerChargeService'
import shipService from '@/http/requests/ship/shipService'
// import commonEntityService from '@/http/requests/common/commonEntityService'
import { Notify } from '@robustshell/utils/index'
import SearchFilter from '@/http/models/search-filters/CustomerChargeFilter'
import { customerChargeDataSource } from './customerChargeStore'

export default {
  components: {
    'customer-charge-form': () => import('./CustomerChargeForm.vue'),
  },
  mixins: [GridBase, Filters],
  data: () => ({
    customerChargeDataSource,
    isVisible: false,
    selectedRow: {},
    filters: {
      query: '',
      filterOn: [],
    },
    stores: [],
    selectedStore: 0,
    warehouses: [],
    selectedWarehouse: 0,
    chargeTypes: [
      { text: 'Charge Type (All)', value: 'all' },
      { text: 'Invoice', value: 'invoice' },
      { text: 'Charge', value: 'charge' },
      { text: 'Refund', value: 'refund' },
    ],
    selectedChargeType: 'all',
    accountNo: '',
  }),
  computed: {
    dataGrid() {
      return this.$refs.customerChargeGridRef.instance
    },
    hasAccountReadPermission() {
      return this.$can('read', 'resource_customer_account_no')
    },
    hasCustomerChargePermission() {
      return this.$can('read', 'resource_customer_charge_update')
    },
    hasPermission() {
      return this.$can('read', 'resource_customer_account_no')
    },
  },
  created() {
    this.getWarehouses()
    this.getStores()
  },
  mounted() {
    this.initialLoad()
  },
  methods: {
    initialLoad() {
      const filters = new SearchFilter()
      filters.accountNo = this.accountNo
      filters.warehouseId = this.selectedWarehouse
      filters.storeId = this.selectedStore
      filters.chargeType = this.selectedChargeType
      filters.q = this.filters.query
      const dataSource = this.dataGrid.getDataSource()
      dataSource.searchValue(filters)
      dataSource.load()
    },
    reload() {
      this.accountNo = ''
      this.selectedWarehouse = 0
      this.selectedStore = 0
      this.selectedChargeType = 'all'
      this.filters.query = ''
      const filters = new SearchFilter()
      filters.accountNo = this.accountNo
      filters.warehouseId = this.selectedWarehouse
      filters.storeId = this.selectedStore
      filters.chargeType = this.selectedChargeType
      filters.q = this.filters.query
      const dataSource = this.dataGrid.getDataSource()
      dataSource.searchValue(filters)
      this.dataGrid.clearFilter('search')
      dataSource.reload()
    },
    reloadAfterUpdate() {
      this.isVisible = false
      this.reload()
    },
    onToolbarPreparing(e) {
      const toolbarItems = e.toolbarOptions.items
      const tempToolbarItems = [...toolbarItems]
      toolbarItems.splice(0, toolbarItems.length)

      toolbarItems.unshift({
        location: 'before',
        template: 'toolbarFilters',
      })
      tempToolbarItems.forEach(item => {
        toolbarItems.push(item)
      })
      toolbarItems.push({
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          onClick: () => {
            this.reload()
          },
        },
        location: 'after',
      })
      if (this.hasCustomerChargePermission) {
        toolbarItems.push({
          widget: 'dxButton',
          options: {
            icon: 'plus',
            onClick: () => {
              this.isVisible = true
            },
          },
          location: 'after',
        })
      }
    },
    onEditorPreparing(e) {
      if (e.parentType === 'searchPanel') {
        e.editorOptions.onValueChanged = arg => {
          const query = arg.component.option('value')
          if (!query) {
            this.filters.query = query.trim()
            e.component.searchByText('')
            this.searchByFilter()
          }
        }
        e.editorOptions.onKeyDown = arg => {
          if (arg.event.keyCode === 13) {
            let query = arg.component.option('value')
            query = query.trim()
            arg.component.option('value', query)
            this.filters.query = query
            this.searchByFilter()
            e.component.searchByText(query)
          }
          if (arg.event.keyCode === 27) {
            e.component.searchByText('')
            arg.component.option('value', '')
            this.filters.query = ''
            this.searchByFilter()
          }
        }
      }
    },
    onFilterByAccountNoLink(e, row) {
      this.accountNo = row.data.accountNo.toString()
      this.getStores(this.accountNo)
      this.searchByFilter()
    },
    onFilterByAccountNo(e) {
      if (e.event && e.event.keyCode === 13) {
        if (e.event.target.value !== '' && e.event.target.value !== null) {
          this.accountNo = e.event.target.value.toString()
          this.searchByFilter()
          this.getStores(this.accountNo)
        }
      } else if ((!e.value && e.event && e.event.type && e.event.type !== 'keydown') || (e.event && e.event.keyCode === 27)) {
        this.accountNo = null
        this.searchByFilter()
        this.getStores(this.accountNo)
      }
    },
    searchByFilter() {
      const filters = new SearchFilter()
      filters.accountNo = this.accountNo
      filters.warehouseId = this.selectedWarehouse
      filters.storeId = this.selectedStore
      filters.chargeType = this.selectedChargeType
      filters.q = this.filters.query
      const dataSource = this.dataGrid.getDataSource()
      dataSource.searchValue(filters)
      dataSource.reload()
    },
    resolveChargeTypeStyle(type) {
      if (type === 'invoice') return 'badge-light-success'
      if (type === 'refund') return 'badge-light-warning'
      if (type === 'charge') return 'badge-light-danger'
      return 'primary'
    },
    deleteItem(e) {
      if (e.row.data.invoiceNo !== 0) {
        Notify.error('An invoiced charge cannot be deleted.')
        return
      }
      const id = e.row.data.id
      const result = confirm('Are you sure deleting the charge?', 'Delete charge')
      result.then(isConfirmed => {
        if (isConfirmed) {
          customerChargeService.deleteById(id).then(() => {
            this.reload()
          })
        }
      })
    },
    getWarehouses() {
      this.warehouses.push({ value: 0, text: 'Location (All)' })
      shipService.fetchWarehouses().then(result => {
        result.data.forEach(element => {
          if (element.name !== 'Common') {
            this.warehouses.push({ value: element.id, text: element.name })
          }
        })
      })
    },
    // getStores() {
    //   this.stores.splice(0, this.stores.length)
    //   this.stores.push({ value: 0, text: 'Stores (All)' })
    //   commonEntityService.fetchStoresForCurrentCompany().then(result => {
    //     const data = result.data
    //     data.forEach(element => {
    //       this.stores.push({ value: element.id, text: element.name })
    //     })
    //     this.selectedStore = 0
    //   })
    // },
    editItem(e) {
      if (e.row.data.invoiceNo !== 0) {
        Notify.error('An invoiced Charge cannot be edited.')
        return
      }
      this.selectedRow = e.row.data
      this.isVisible = true
    },
  },
}
</script>

<style lang="scss">
.smallCell {
   width: 100px;
}
.mediumCell {
   width: 120px;
}
</style>
