import DataSource from 'devextreme/data/data_source'
import CustomStore from 'devextreme/data/custom_store'
import Pager from '@core/dev-extreme/utils/pager'
import customerChargeService from '@/http/requests/finance/customerChargeService'

const store = new CustomStore({
  key: 'id',
  cacheRawData: false,
  load: async loadOptions => {
    const options = {}
    const sort = (loadOptions.sort && loadOptions.sort.length > 0) ? loadOptions.sort : [{ selector: 'id', desc: false }]
    const pager = new Pager(options, sort)
    pager.setPageNumber(loadOptions.skip, loadOptions.take)
    pager.setSortOrder(sort)
    const pageableQuery = pager.toCreatePageable()
    const filters = (loadOptions.searchValue) ? loadOptions.searchValue : null
    const response = await customerChargeService.fetchAllByQuery(filters, pageableQuery)
    const data = response.data
    return {
      data: data.content,
      totalCount: data.totalElements,
    }
  },
  insert: values => {
    customerChargeService.create(values)
  },
  update: (key, values) => {
    const data = {
      id: key,
      ...values,
    }
    customerChargeService.update(data)
  },
  remove: key => {
    customerChargeService.deleteById(key)
  },

})

const customerChargeDataSource = new DataSource({
  store: store,
})

export {
  customerChargeDataSource,
}
